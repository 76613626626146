export const addSpaceInLargeNumber = (nStr: string) => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]!
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ' ' + '$2')
  }
  return x1 + x2
}

export const formatLargeNumber = (num: number, withHtml: boolean = true) => {
  if (Math.abs(num) > 999999) {
    return `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(3))}${
      withHtml ? ' <span class="medium">M</span>' : ' M'
    }`
  } else if (Math.abs(num) > 999) {
    return `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}${
      withHtml ? ' <span class="medium">k</span>' : ' k'
    }`
  } else {
    return Math.sign(num) * Math.abs(num)
  }
}

export const formatFloatNumber = (
  floatNumber: number,
  numberOfSignificantDigits: number,
  maxNumberOfDecimalDigits: number
) => {
  return parseFloat(
    parseFloat(floatNumber.toPrecision(numberOfSignificantDigits)).toFixed(
      maxNumberOfDecimalDigits
    )
  )
}

export const isStringConvertibleToANumber = (value: string) => {
  return !isNaN(Number(value)) && !isNaN(parseFloat(value))
}
